import React, { useState, useEffect, useRef } from "react";
import "./App.css";
import { useScrollWatch } from "react-smooth-scroll-hook";
import Rellax from "rellax";
import { useResizeDetector } from "react-resize-detector";
import HomeSection from "./Components/HomeSection";
import Navbar from "./Components/Navbar";
import AboutSection from "./Components/AboutSection";
import ServicesSection from "./Components/ServicesSection";
import ContactLocationSection from "./Components/ContactLocationSection";
import MessageSection from "./Components/MessageSection";

const App = () => {
  const [windHeight, setWindHeight] = useState(0);
  const [scrollTopAt, setScrollTopAt] = useState(0);
  const [gradualDarken, setGradualDarken] = useState(0);
  const [gradualLighten, setGradualLighten] = useState(1);
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingText, setLoadingText] = useState(false);
  const [loadingMenu, setLoadingMenu] = useState(false);
  const [menuChange, setMenuChange] = useState(false);
  const [downScroll, setDownScroll] = useState(false);
  const [startMessSec, setStartMessSec] = useState(false);
  const [openMessSec, setOpenMessSec] = useState(false);
  const [mainScolSecCl, setMainScolSecSL] = useState("mainScrollTop");
  const [wrapClass, setWrapClass] = useState("siteWrapperTop");
  const [offset, setOffset] = useState(0);
  const [aboutSecTop, setAboutSecTop] = useState(0);
  const [servicesSecTop, setServicesSecTop] = useState(0);
  const [contactLocTop, setContactLocTop] = useState(0);
  const targetRef = useRef();
  const { width, height } = useResizeDetector({ targetRef });
  const ref = useRef(null);
  const { scrollTop, curIndex, curItem } = useScrollWatch({
    ref,
    list: [
      {
        href: "#HomeSection",
      },
      {
        href: "#AboutSection",
      },
      {
        href: "#ServicesSection",
      },
      {
        href: "#ContactSection",
      },
      {
        href: "#LocationSection",
      },
    ],
    offset: -250,
  });

  useEffect(() => {
    startLoad();
    new Rellax(".homeSecOuter", {
      speed: -5,
      center: false,
      wrapper: ".mainScrollTop",
      round: true,
      vertical: true,
      horizontal: false,
    });
  }, []);
  useEffect(() => {
    let topCont = scrollTop;
    verticalScroll(topCont);
    getSectionsTop();
    let parSpeed = Math.floor(topCont / 2);
    let areScrol = "translateY(" + parSpeed + "px";
    setOffset(areScrol);
  }, [scrollTop]);

  const getSectionsTop = () => {
    let aboutTop = document.getElementById("AboutSection").offsetTop;
    let passAbTop = aboutTop - 80;
    let serviceTop = document.getElementById("ServicesSection").offsetTop;
    let passSerTop = serviceTop - 80;
    let conLocTop = document.getElementById("ContactLocation").offsetTop;
    let passCLTop = conLocTop - 80;
    setAboutSecTop(passAbTop);
    setServicesSecTop(passSerTop);
    setContactLocTop(passCLTop);
  };
  const navBarclick = name => {
    const curScroll = ref.current;
    let secTo = name;
    let goTo =
      secTo === "HomeSection"
        ? 0
        : secTo === "AboutSection"
        ? aboutSecTop
        : secTo === "ServicesSection"
        ? servicesSecTop
        : contactLocTop;
    curScroll.scrollTo(0, goTo);
  };

  const aboutSectionClick = () => {
    const curScroll = ref.current;
    let aboutTop = aboutSecTop;
    curScroll.scrollTo(0, aboutTop);
  };

  const serviceSecClick = () => {
    const curScroll = ref.current;
    let serviceTop = servicesSecTop;
    curScroll.scrollTo(0, serviceTop);
  };

  const startLoad = () => {
    setTimeout(() => {
      let count = document.getElementById("counterVal");
      let countVal = count.value;
      let countAt = parseInt(countVal);
      checkLoad(countAt);
    }, 400);
  };
  const checkLoad = countAt => {
    let counted = countAt || 0;
    if (counted < 16) {
      setTimeout(() => {
        let count = document.getElementById("counterVal");
        let countVal = count.value;
        let countAt = parseInt(countVal);
        checkLoad(countAt);
      }, 400);
    } else if (counted >= 16) {
      document.getElementById("main-loader").style.display = "none";
      document.getElementById("counterVal").style.display = "none";
      loadedFunc();
    }
  };
  const loadedFunc = () => {
    setLoadingPage(true);
    loadingTextFunc();
  };

  const loadingTextFunc = () => {
    setTimeout(() => {
      setLoadingText(true);
      loadingMenuFunc();
    }, 360);
  };
  const loadingMenuFunc = () => {
    setTimeout(() => {
      let ScreenHight = ref.current.clientHeight;
      setWindHeight(ScreenHight);
      setLoadingMenu(true);
    }, 360);
  };

  const addPadFunction = scrollIs => {
    let scrollAt = scrollIs;
    if (scrollAt <= 730) {
      let perScroll = scrollIs / 660;
      let opNum = perScroll.toFixed(2);
      let redOp = opNum * 0.9;
      let fixOp = redOp.toFixed(2);
      let ltNum = 1 - fixOp;
      setGradualDarken(opNum);
      setGradualLighten(ltNum);
    } else if (scrollAt > 730) {
      setGradualDarken(1);
    }
  };

  const changeBack = scrollTop => {
    let scrollAt = scrollTop;
    if (scrollAt <= 100) {
      setWrapClass("siteWrapperTop");
    } else if (scrollAt > 100 && scrollAt <= 730) {
      setWrapClass("siteWrapperMove");
    } else if (scrollAt > 730) {
      setWrapClass("siteWrapperDown");
    }
  };
  function changeScrollClass(curSec) {
    let section = curSec;
    if (section === "#HomeSection") {
      setMainScolSecSL("mainScrollTop");
      return section;
    } else if (section === "#AboutSection") {
      setMainScolSecSL("mainScrollAbout");
      return section;
    } else if (section === "#ServicesSection") {
      setMainScolSecSL("mainScrollService");
      return section;
    } else {
      setMainScolSecSL("mainScrollContact");
      return section;
    }
  }

  const verticalScroll = scrollTop => {
    let scrollIs = scrollTop;
    setScrollTopAt(scrollIs);
    addPadFunction(scrollIs);
    changeBack(scrollIs);
    let curSec = "" + curItem.href + "";
    changeScrollClass(curSec);
    if (scrollIs > 100) {
      setMenuChange(true);
    }
    if (scrollIs <= 100) {
      setMenuChange(false);
    }
    if (scrollIs >= 200) {
      setDownScroll(true);
    }
    if (scrollIs < 200) {
      setDownScroll(false);
    }
    return scrollTop;
  };

  const activateMessageSec = () => {
    setStartMessSec(true);
    setTimeout(() => {
      setOpenMessSec(true);
    }, 360);
  };

  const resetMessageOuter = () => {
    setTimeout(() => {
      setOpenMessSec(false);
      setStartMessSec(false);
    }, 360);
  };

  return (
    <div id="siteWrapper" className={wrapClass}>
      <Navbar
        loadMenu={loadingMenu}
        changeMenu={menuChange}
        navBarclick={navBarclick}
        width={width}
        activateMessageSec={activateMessageSec}
      />
      <div
        id="mainScrollArea"
        className="mainScrollTop"
        style={{
          padding: "0px",
          maxHeight: "100vh",
          width: "100%",
          overflowY: "scroll",
          overflowX: "hidden",
          pointerEvents: "all",
          touchAction: "manipulation",
        }}
        ref={ref}
      >
        <div
          ref={targetRef}
          id="DocBodHeightDv"
          style={{
            padding: "0px",
            margin: "0px",
            height: "auto",
            width: "100%",
          }}
        >
          <HomeSection
            relSecCl={"homeSecOuter"}
            offset={offset}
            loaded={loadingPage}
            loadText={loadingText}
            gradDark={gradualDarken}
            gradLight={gradualLighten}
            scrolledDown={downScroll}
          />{" "}
          <AboutSection width={width} aboutSectionClick={aboutSectionClick} />{" "}
          <ServicesSection
            vertAt={scrollTopAt}
            height={windHeight}
            serviceSecClick={serviceSecClick}
          />
          <ContactLocationSection navBarclick={navBarclick} />
        </div>
      </div>
      <MessageSection
        messageSecStart={startMessSec}
        messSecOpen={openMessSec}
        changeMenu={menuChange}
        resetMessageOuter={resetMessageOuter}
      />
    </div>
  );
};

export default App;
