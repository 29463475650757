import React, { useState } from "react";
import "./FormInput.css";

const inputNotReadyFI = "formInputNotReady";
const inputStartFI = "formInputStart";
const inputSetShowFI = "formInputSet";

const FormInput = ({ inputTitle, inputAction, needed, passFormInputVal }) => {
  const [value, setValue] = useState("");
  const [inputIsAtFI, setInputReadyFI] = useState(inputNotReadyFI);
  const [labelTextFI, setLabelTextFI] = useState(inputTitle);
  const [boxTouched, setBoxTouched] = useState("formNoTouch");
  const [labelClass, setLabelClass] = useState("labelStart");

  const focusInput = () => {
    setBoxTouched("formTouched");
    setLabelClass("labelEnd");
    setInputReadyFI(inputStartFI);
    setLabelTextFI(inputAction);
  };

  const handleChange = event => {
    setValue(event.target.value);
    passFormInputVal(event.target.value);
  };
  const preventDef = event => {
    event.preventDefault();
  };
  const handleBlur = event => {
    const val = event.target.value;
    const valLength = val.length;
    if (valLength > 0) {
      setInputReadyFI(inputSetShowFI);
      setLabelClass("labelEnd");
    } else {
      let needVar = "Need" + " " + inputAction;
      setBoxTouched("formNoTouch");
      setLabelTextFI(needVar);
      setLabelClass("labelProblem");
      setInputReadyFI(inputNotReadyFI);
    }
  };
  return (
    <form onSubmit={preventDef}>
      <div className="formInputWrapper">
        <input
          type="text"
          name={inputTitle}
          value={value}
          className={inputIsAtFI}
          onFocus={focusInput}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <div className={boxTouched}> </div>
        <label className={labelClass}>{labelTextFI}</label>
        <div className="needTextHolder" style={{ color: "#ccdeff" }}>
          {needed}
        </div>
      </div>
    </form>
  );
};

export default FormInput;
