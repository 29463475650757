import React, { useState, useEffect } from "react";
import vantageIllustration from "../images/vantageIllustration.jpg";
import mbsSolutionsImage from "../images/mbsSolutionsImage.jpg";
import partnershipIllustration from "../images/partnershipIllustration.jpg";

const AboutSection = ({ width, aboutSectionClick }) => {
  const [infoSec, setInfoSec] = useState(false);
  const [vantageSec, setVantageSec] = useState(false);
  const [partBtnWidth, setPartBtnWidth] = useState("500px");
  const [mbsSection, setMbsSection] = useState(false);
  const [partnerSection, setPartnerSection] = useState(false);
  useEffect(() => {
    let windowInWid = width;
    buttomWidthFunc(windowInWid);
  }, [width]);
  const buttomWidthFunc = windowInWid => {
    let partWid = windowInWid * 0.4;
    let pixWid = "" + partWid + "px";
    setPartBtnWidth(pixWid);
  };

  const startVantageSec = () => {
    setTimeout(() => {
      setVantageSec(true);
    }, 320);
  };
  const startMBSSec = () => {
    setTimeout(() => {
      setMbsSection(true);
    }, 320);
  };
  const startPartnerSec = () => {
    setTimeout(() => {
      setPartnerSection(true);
    }, 320);
  };
  const endInfoSec = () => {
    setTimeout(() => {
      setInfoSec(false);
    }, 320);
  };
  return (
    <div
      id="AboutSection"
      className={
        infoSec
          ? infoSec && partnerSection
            ? "aboutSectionInfo"
            : infoSec && vantageSec
            ? "aboutSectionVantage"
            : "aboutSectionMBS"
          : "aboutSectionHolder"
      }
    >
      <div
        className="ourPartnershipBtn"
        role="button"
        onClick={() => {
          setInfoSec(!infoSec);
          startPartnerSec();
          aboutSectionClick();
        }}
      >
        <div className="ourPartDivColor">
          <div
            className="relativePartBtn"
            style={{
              width: partBtnWidth,
            }}
          >
            <div className="abtOurPartBtn" role="button">
              <div className="butRelInner">
                <div className="whiteTriangleDiv"> </div>{" "}
                <div className="btnTextHolder">
                  <div className="circleInfoIcon">
                    {" "}
                    <b> i </b>{" "}
                  </div>{" "}
                  <div className="abtBtnInBlText">
                    {" "}
                    <b> About </b>{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
        <div className="redTriangleDiv"> </div>{" "}
        <div id="#partnerTextImg" className="partTextImgHolderReg">
          {" "}
        </div>{" "}
        <div id="#partnerTextWhite" className="partTextImgHolderWhite">
          {" "}
        </div>{" "}
      </div>{" "}
      <div
        className="vantageAboutBtn"
        role="button"
        onClick={() => {
          setInfoSec(!infoSec);
          startVantageSec();
          aboutSectionClick();
        }}
      >
        <div className="vantageImgHolderReg"> </div>{" "}
        <div className="vantageImgHolderWhite"> </div>{" "}
        {/* <div
          className="vantageWebButton"
          role="link"
          onClick={event => {
            event.preventDefault();
            window.open("https://www.vhcn.com/", "_blank");
          }}
        >
          <div className="topVanBtnRelative">
            <span className="vicon-Vantage-Logo-Only"> </span>{" "}
            <span className="vicon-Website-Icon"> </span>{" "}
            <div className="vVanSiteText">
              {" "}
              <b> Visit Our </b> Website{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "} */}
        <div className="abtHealthNetwork" role="button">
          <div className="butRelInner">
            <div className="whiteTriStand"> </div>{" "}
            <div className="btnTextStand">
              <div className="circleInfoVant">
                <b> i </b>{" "}
              </div>{" "}
              <div className="abtBtnInStand">
                <b> Interior Spaces </b>{" "}
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
        <div className="leftbuttonBorder"> </div>{" "}
      </div>{" "}
      <div
        className="MBSAboutBtn"
        role="button"
        onClick={() => {
          setInfoSec(!infoSec);
          startMBSSec();
          aboutSectionClick();
        }}
      >
        <div className="MBSImgHolderReg"> </div>{" "}
        <div className="MBSImgHolderWhite"> </div>{" "}
        <div
          className="MBSWebButton"
          role="link"
          onClick={event => {
            event.preventDefault();
            window.open("https://millerbrotherstaffing.com/", "_blank");
          }}
        >
          <div className="topRtBtnRelative">
            <span className="viconSec-mbsLogoFont"> </span>{" "}
            <span className="vicon-Website-Icon"> </span>{" "}
            <div className="MBSSiteText">
              {" "}
              <b> Visit Our </b> Website{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
        <div className="MBSSolutionsBtn" role="button">
          <div className="butRelInner">
            <div className="whiteTriStand"> </div>{" "}
            <div className="btnTextMBS">
              <div className="circleInfoMBS">
                <b> i </b>{" "}
              </div>{" "}
              <div className="abtBtnInStand">
                <b> Exterior </b> <span>Services</span>{" "}
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
        <div className="leftbuttonBorder"> </div>{" "}
      </div>{" "}
      <div className={infoSec ? "aboutSectionOverlay" : "aboutSectionHidden"}>
        <div className="aboutInfoWrapper">
          <div className={vantageSec ? "sectionAboutShow" : "sectionAboutHide"}>
            <div className="vantageBuidlingHold">
              <img
                src={vantageIllustration}
                alt="Vangage Office Building"
                className="vantageBuilding"
                role="link"
                onClick={event => {
                  event.preventDefault();
                  window.open("https://www.vhcn.com/", "_blank");
                }}
              />
              <div className="pictureOverlay" />
              <div className="vantageHealthNetback" />
              <div className="vantageHealthNetText" />
              <div
                className="vantageWebButton"
                role="link"
                onClick={event => {
                  event.preventDefault();
                  window.open("https://www.vhcn.com/", "_blank");
                }}
              >
                <div className="topVanBtnRelative">
                  <span className="vicon-Vantage-Logo-Only"> </span>{" "}
                  <span className="vicon-Website-Icon"> </span>{" "}
                  <div className="vVanSiteText">
                    <b> Visit Our </b> Website{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
            <div className="aboutInfoScrollBar">
              <h4 className="aboutInfoSecHead">We are here to serve you!</h4>{" "}
              <p className="topVantageTextPara">
                {" "}
                We specialize in concrete, roofing, siding additions, and decks.
                We have extensive experience in home or commercial exteriors and
                can work with what you have or build it from the ground up.
                Residential clients can get a quote by clicking the Request
                button below, and commercial client can click the Inquiry
                button. We look forward to talking with you soon!{" "}
              </p>{" "}
              <p className="infoSecParagraph">
                We specialize in concrete, roofing, siding additions, and decks.{" "}
              </p>{" "}
            </div>{" "}
          </div>{" "}
          <div className={vantageSec ? "aboutInfoBtnHold" : "aboutInfoBtnHide"}>
            <div className="inBtnHolder">
              <div
                className="backInfoButton"
                role="button"
                onClick={() => {
                  setVantageSec(!vantageSec);
                  endInfoSec();
                  aboutSectionClick();
                }}
              >
                <div className="whiteBtnBack">
                  <span className="vicon-arrow-left"> </span>{" "}
                </div>
              </div>
              {/* <div
                className="backInfoButton"
                role="button"
                onClick={() => {
                  setVantageSec(!vantageSec);
                  startPartnerSec();
                  aboutSectionClick();
                }}
              >
                <div className="whiteBtnBack" />
                <div className="partnerIconWhite" />
                <div className="partnerIconBack" />
              </div>{" "} */}
              {/* <div
                className="MBSInfoButton"
                role="button"
                onClick={() => {
                  setVantageSec(!vantageSec);
                  startMBSSec();
                  aboutSectionClick();
                }}
              >
                <span className="viconSec-mbsLogoFont"> </span>{" "}
              </div>{" "} */}
            </div>{" "}
          </div>{" "}
          <div
            className={
              vantageSec ? "aboutInfoBtnbottomHold" : "aboutInfoBtnbottomHide"
            }
          >
            <div className="inBtnBottomHolder">
              <div
                className="backInfoButton"
                role="button"
                onClick={() => {
                  setVantageSec(!vantageSec);
                  endInfoSec();
                  aboutSectionClick();
                }}
              >
                <div className="whiteBtnBack">
                  <span className="vicon-arrow-left"> </span>{" "}
                </div>{" "}
              </div>{" "}
              {/* <div
                className="backInfoButton"
                role="button"
                onClick={() => {
                  setVantageSec(!vantageSec);
                  startPartnerSec();
                  aboutSectionClick();
                }}
              >
                <div className="whiteBtnBack" />
                <div className="partnerIconWhite" />
                <div className="partnerIconBack" />
              </div>{" "} */}
              {/* <div
                className="MBSInfoButton"
                role="button"
                onClick={() => {
                  setVantageSec(!vantageSec);
                  startMBSSec();
                  aboutSectionClick();
                }}
              >
                <span className="viconSec-mbsLogoFont"> </span>{" "}
              </div>{" "} */}
            </div>{" "}
          </div>{" "}
          <div className={mbsSection ? "sectionAboutShow" : "sectionAboutHide"}>
            <div
              className="mbsIllustration"
              role="link"
              onClick={event => {
                event.preventDefault();
                window.open("https://millerbrotherstaffing.com/", "_blank");
              }}
            >
              <img
                src={mbsSolutionsImage}
                alt="MBS Soluteions"
                className="vantageBuilding"
              />
              <div className="pictureOverlay" />
              <div className="mbsSolutionsback" />
              <div className="mbsSolutionsText" />
              <div
                className="MBSWebButton"
                role="link"
                onClick={event => {
                  event.preventDefault();
                  window.open("https://millerbrotherstaffing.com/", "_blank");
                }}
              >
                <div className="topRtBtnRelative">
                  <span className="viconSec-mbsLogoFont"> </span>{" "}
                  <span className="vicon-Website-Icon"> </span>{" "}
                  <div className="MBSSiteText">
                    <b> Visit Our </b> Website{" "}
                  </div>{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
            <div className="aboutInfoScrollBar">
              <h4 className="aboutMBSSecHead">The right style for you </h4>{" "}
              <p className="infoSecParagraph">
                Whether it's a simple remodell you need, or something built from
                the ground up, we will accomodate and execute in a timely
                manner.{" "}
              </p>{" "}
              <p className="infoSecParagraph">
                We do complete or partial kitchen and bathroom remodelling,
                lamanent flooring installation or replacement, custome kitchen
                and bathroom tile work, and custom showers and we will make it
                as simple or as elaborate as your needs or tastes require.{" "}
              </p>{" "}
            </div>{" "}
          </div>{" "}
          <div className={mbsSection ? "aboutInfoBtnHold" : "aboutInfoBtnHide"}>
            <div className="inBtnHolder">
              <div
                className="backInfoButton"
                role="button"
                onClick={() => {
                  setMbsSection(!mbsSection);
                  endInfoSec();
                  aboutSectionClick();
                }}
              >
                <div className="whiteBtnBack">
                  <span className="vicon-arrow-left"> </span>{" "}
                </div>{" "}
              </div>{" "}
              {/* <div
                className="backInfoButton"
                role="button"
                onClick={() => {
                  setMbsSection(!mbsSection);
                  startPartnerSec();
                  aboutSectionClick();
                }}
              >
                <div className="whiteBtnBack" />
                <div className="partnerIconWhite" />
                <div className="partnerIconBack" />
              </div>{" "} */}
              {/* <div
                className="VantageInfoButton"
                role="button"
                onClick={() => {
                  setMbsSection(!mbsSection);
                  startVantageSec();
                  aboutSectionClick();
                }}
              >
                <span className="vicon-Vantage-Logo-Only"> </span>{" "}
              </div>{" "} */}
            </div>{" "}
          </div>{" "}
          <div
            className={
              mbsSection ? "aboutInfoBtnbottomHold" : "aboutInfoBtnbottomHide"
            }
          >
            <div className="inBtnBottomHolder">
              <div
                className="backInfoButton"
                role="button"
                onClick={() => {
                  setMbsSection(!mbsSection);
                  endInfoSec();
                  aboutSectionClick();
                }}
              >
                <div className="whiteBtnBack">
                  <span className="vicon-arrow-left"> </span>{" "}
                </div>{" "}
              </div>{" "}
              {/* <div
                className="backInfoButton"
                role="button"
                onClick={() => {
                  setMbsSection(!mbsSection);
                  startPartnerSec();
                  aboutSectionClick();
                }}
              >
                <div className="whiteBtnBack" />
                <div className="partnerIconWhite" />
                <div className="partnerIconBack" />
              </div>{" "} */}
              {/* <div
                className="VantageInfoButton"
                role="button"
                onClick={() => {
                  setMbsSection(!mbsSection);
                  startVantageSec();
                  aboutSectionClick();
                }}
              >
                {" "}
                <span className="vicon-Vantage-Logo-Only"> </span>{" "}
              </div>{" "} */}
            </div>{" "}
          </div>{" "}
          <div
            className={partnerSection ? "sectionAboutShow" : "sectionAboutHide"}
          >
            <div className="mbsIllustration">
              <img
                src={partnershipIllustration}
                alt="Vantage and MBS Partnership"
                className="vantageBuilding"
              />
            </div>{" "}
            <div className="aboutInfoScrollBar">
              <h4 className="aboutMBSSecHead">The right style for you </h4>{" "}
              <p className="infoSecParagraph">
                Whether it's a simple remodell you need, or something built from
                the ground up, we will accomodate and execute in a timely
                manner.{" "}
              </p>{" "}
              <p className="infoSecParagraph">
                We do complete or partial kitchen and bathroom remodelling,
                lamanent flooring installation or replacement, custome kitchen
                and bathroom tile work, and custom showers and we will make it
                as simple or as elaborate as your needs or tastes require.{" "}
              </p>{" "}
            </div>{" "}
          </div>{" "}
          <div
            className={partnerSection ? "aboutInfoBtnHold" : "aboutInfoBtnHide"}
          >
            <div className="inBtnHolder">
              <div
                className="backInfoButton"
                role="button"
                onClick={() => {
                  setPartnerSection(!partnerSection);
                  endInfoSec();
                  aboutSectionClick();
                }}
              >
                <div className="whiteBtnBack">
                  <span className="vicon-arrow-left"> </span>{" "}
                </div>{" "}
              </div>{" "}
              {/* <div
                className="VantageInfoButton"
                role="button"
                onClick={() => {
                  setPartnerSection(!partnerSection);
                  startVantageSec();
                  aboutSectionClick();
                }}
              >
                <span className="vicon-Vantage-Logo-Only"> </span>{" "}
              </div>{" "}
              <div
                className="MBSInfoButton"
                role="button"
                onClick={() => {
                  setPartnerSection(!partnerSection);
                  startMBSSec();
                  aboutSectionClick();
                }}
              >
                <span className="viconSec-mbsLogoFont"> </span>{" "}
              </div>{" "} */}
            </div>{" "}
          </div>{" "}
          <div
            className={
              partnerSection
                ? "aboutInfoBtnbottomHold"
                : "aboutInfoBtnbottomHide"
            }
          >
            <div className="inBtnBottomHolder">
              <div
                className="backInfoButton"
                role="button"
                onClick={() => {
                  setPartnerSection(!partnerSection);
                  endInfoSec();
                  aboutSectionClick();
                }}
              >
                <div className="whiteBtnBack">
                  <span className="vicon-arrow-left"> </span>{" "}
                </div>{" "}
              </div>{" "}
              {/* <div
                className="VantageInfoButton"
                role="button"
                onClick={() => {
                  setPartnerSection(!partnerSection);
                  startVantageSec();
                  aboutSectionClick();
                }}
              >
                {" "}
                <span className="vicon-Vantage-Logo-Only"> </span>{" "}
              </div>{" "}
              <div
                className="MBSInfoButton"
                role="button"
                onClick={() => {
                  setPartnerSection(!partnerSection);
                  startMBSSec();
                  aboutSectionClick();
                }}
              >
                <span className="viconSec-mbsLogoFont"> </span>{" "}
              </div>{" "} */}
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </div>{" "}
    </div>
  );
};

export default AboutSection;
