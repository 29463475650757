import React, { useState, useEffect, useRef } from "react";
import messageIcon from "../images/messageIcon.svg";
import messageRollIcon from "../images/messageRollIcon.svg";
import useOuterClick from "./useOuterClick";
import FormInput from "./FormInput/FormInput";
import EmailInput from "./EmailInput/EmailInput";
import ParagraphText from "./ParagraphText/ParagraphText";
import { fetchPostService } from "../CommonServices/fetchService";

const MessageSection = ({
  messageSecStart,
  messSecOpen,
  changeMenu,
  resetMessageOuter,
}) => {
  const [messFormShow, setMessFormShow] = useState(false);
  const [thankMessSec, setThankMessSec] = useState(false);
  const [messageName, setMessageName] = useState("");
  const [messageEmail, setMessageEmail] = useState("");
  const [messageText, setMessageText] = useState("");
  const messageAreaRef = useOuterClick(e => {
    checkMessageAreaStat();
  });

  const checkMessageAreaStat = () => {
    let contentStat = messFormShow;
    if (contentStat) {
      let resOutStat = changeMenu;
      !resOutStat && resetMessageOuter();
      setMessFormShow(false);
      setThankMessSec(false);
    }
  };

  useEffect(() => {
    let openStat = messSecOpen;
    messageStatusOpen(openStat);
  }, [messSecOpen]);
  const messageStatusOpen = openStat => {
    if (openStat) {
      setMessFormShow(openStat);
    }
  };
  const passMessageName = value => {
    let messanger = value;
    setMessageName(messanger);
  };

  const passMessageText = value => {
    let message = value;
    setMessageText(message);
  };
  const sendMessage = () => {
    fetchPostService("/api/v1/cmbs/sendMessage", {
      lastName: messageName,
      email: messageEmail,
      message: messageText,
    })
      .then(response => {
        setThankMessSec(true);
        resetMessageForm();
        setTimeout(() => {
          let resOutStat = changeMenu;
          setMessFormShow(false);
          !resOutStat && resetMessageOuter();
          resetMessSection();
        }, 7000);
      })
      .catch(err => {
        console.log(err);
        alert("Failed, please try submitting again");
      });
  };
  const resetMessageForm = () => {
    setMessageName("");
    setMessageEmail("");
    setMessageText("");
  };
  const resetMessSection = () => {
    setTimeout(() => {
      setThankMessSec(false);
    }, 300);
  };
  return (
    <div
      ref={messageAreaRef}
      className={
        messageSecStart
          ? "messageSecHold"
          : changeMenu
          ? "messageSecScroll"
          : "messageSecHide"
      }
      style={{ zIndex: "1200" }}
    >
      <div
        className={
          messFormShow
            ? thankMessSec
              ? "messageSecThankyou"
              : "messageSecActive"
            : changeMenu
            ? "messageSecDownInActive"
            : "messageSecInActive"
        }
      >
        <div
          role="button"
          className={
            changeMenu
              ? messFormShow
                ? "messageUsBtnDownHide"
                : "messageUsBtnDown"
              : messFormShow
              ? "messageUsBtnHide"
              : "messageUsBtn"
          }
          onClick={() => {
            setMessFormShow(!messFormShow);
          }}
        >
          <div
            style={{
              position: "realtive",
              width: "100%",
              height: "100%",
              top: "0px",
              left: "0px",
            }}
          >
            <img
              src={messageIcon}
              alt="messageStartIcon"
              className="messageIcon"
            />
            <img
              src={messageRollIcon}
              alt="messageStartIconRoll"
              className="messageRollIcon"
            />
          </div>
        </div>
        <div
          id="messageSecFormScroll"
          className={
            messFormShow && !thankMessSec
              ? "messFormHolderShow"
              : "messFormHolderHide"
          }
          style={{
            overflowY: "scroll",
            overflowX: "hidden",
            touchAction: "manipulation",
          }}
        >
          <div className="messageInputHolder">
            <FormInput
              inputTitle={"Your Last Name"}
              inputAction={"Last Name"}
              needed={"* Required"}
              passFormInputVal={value => {
                passMessageName(value);
              }}
            />
          </div>
          <div className="messageInputHolder">
            <EmailInput
              inputTitle={"Please Provide Email Address"}
              inputAction={"Valid Email"}
              passFormInputVal={value => {
                setMessageEmail(value);
              }}
            />
          </div>
          <div className="messageTextBHolder">
            <ParagraphText
              textStart={"Your Message, and Additional Contact Info if Desired"}
              textAction={"Message to Send"}
              passValue={value => {
                passMessageText(value);
              }}
            />
          </div>
          <div className="messageSubmitHolder">
            <div
              className={
                "submitInqButton " +
                (!messageName || !messageEmail || !messageText
                  ? " submitInqButton-disabled"
                  : "")
              }
              role="button"
              onClick={() => {
                messageName && messageEmail && messageText && sendMessage();
              }}
            >
              <span className="messageSubNavIcon" />
              <span>Send Message</span>
            </div>
          </div>
        </div>
        <div
          className={
            messFormShow && thankMessSec
              ? "thankYouMessShow"
              : "thankYouMessHide"
          }
        >
          Thank you for messaging us,
          <span>we will be in touch soon.</span>
        </div>
        <div className={messFormShow ? "closeHolderShow" : "closeHolderHide"}>
          <div
            className="closeInfoButton"
            role="button"
            onClick={() => {
              setMessFormShow(!messFormShow);
              !changeMenu && resetMessageOuter();
            }}
          >
            <div className="greyBtnBack">
              <span className="viconInq-x-icon" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MessageSection;
