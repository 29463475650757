const baseUrl = "https://ygtipwemry.us-west-2.awsapprunner.com";
// const baseUrl = "http://localhost:3000";
const fetchGetService = (url, options = {}) => {
  return new Promise((resolve, reject) => {
    fetch(baseUrl + url, options)
      .then(res => {
        if (res.status == 200) {
          return res.json();
        } else {
          throw Error(res.statusText);
        }
      })
      .then(result => {
        resolve(result);
      })
      .catch(err => reject(err));
  });
};

const fetchPostService = (url, data, options) => {
  return new Promise((resolve, reject) => {
    fetch(baseUrl + url, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
      ...options,
    })
      .then(res => {
        if (res.status == 200) {
          return res.json();
        } else {
          throw Error(res.statusText);
        }
      })
      .then(result => {
        resolve(result);
      })
      .catch(err => reject(err));
  });
};

const fetchFormData = (url, formData, options) => {
  return new Promise((resolve, reject) => {
    fetch(baseUrl + url, {
      method: "POST",
      // mode: "cors",
      headers: {
        // "Content-Type": "multipart/form-data",
        // Accept: "application/json",
        // "Access-Control-Allow-Origin": "*",
        // "Content-Type": "multipart/form-data",
      },
      body: formData,
      ...options,
    })
      .then(res => {
        if (res.status === 200) {
          return res.json();
        } else {
          throw Error(res.statusText);
        }
      })
      .then(result => {
        resolve(result);
      })
      .catch(err => reject(err));
  });
};
export { fetchGetService, fetchPostService, fetchFormData };
