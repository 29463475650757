import React, { useState, useEffect, useRef } from "react";
import VMBSLogoMenu from "../images/VMBSLogoMenu.svg";
import VMBSSideBotLogo from "../images/VMBSSideBotLogo.svg";
import GreyTriangle from "../images/GreyTriangle.svg";
import messageNavIcon from "../images/messageNavIcon.svg";
import messageRollNavIcon from "../images/messageRollNavIcon.svg";
import useOuterClick from "./useOuterClick";

const Navbar = ({
  loadMenu,
  changeMenu,
  navBarclick,
  width,
  activateMessageSec,
}) => {
  const [sideMenuActive, setSideMenuActive] = useState(false);
  const [activeSec, setActiveSec] = useState("");
  const sideMenuRef = useOuterClick(e => {
    checkSideMenuStat();
  });
  const HomeSection = "HomeSection";
  const AboutSection = "AboutSection";
  const ServicesSection = "ServicesSection";
  const ContactSection = "ContactLocation";

  const checkSideMenuStat = () => {
    let menuStat = sideMenuActive;
    if (menuStat) {
      setSideMenuActive(false);
    }
  };

  return (
    <>
      <nav
        className={changeMenu ? "navFullScreenScroll" : "navFullScreen"}
        id="navbar"
      >
        <div className="relWrapper">
          <div
            className={
              loadMenu
                ? changeMenu
                  ? "absoluteNaveHoldChange"
                  : "absoluteNaveHold"
                : "absoluteNaveHoldHide"
            }
          >
            <div className="relWrapper">
              <img
                src={VMBSLogoMenu}
                className="menuLogoFull"
                alt="VMBS Logo"
                role="button"
                onClick={() => {
                  navBarclick(HomeSection);
                }}
              />{" "}
              <div className="absoluteItems">
                <ul className="navItems">
                  <li className="navItem">
                    <button
                      className="linkButton"
                      onClick={() => {
                        navBarclick(AboutSection);
                      }}
                    >
                      {" "}
                      About{" "}
                    </button>{" "}
                  </li>{" "}
                  <li className="navItem">
                    <button
                      className="linkButton"
                      onClick={() => {
                        navBarclick(ServicesSection);
                      }}
                    >
                      Services{" "}
                    </button>{" "}
                  </li>{" "}
                  <li className="navItem">
                    <button
                      className="linkButton"
                      onClick={() => {
                        navBarclick(ContactSection);
                      }}
                    >
                      Contact{" "}
                    </button>{" "}
                  </li>{" "}
                  <li className="navItem">
                    <button
                      className="linkButton"
                      onClick={() => {
                        navBarclick(ContactSection);
                      }}
                    >
                      Location{" "}
                    </button>{" "}
                  </li>
                </ul>{" "}
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </div>
      </nav>
      <div
        className="navMessageBtn"
        style={{
          transform: loadMenu
            ? changeMenu
              ? "translateY(-90px)"
              : "translateY(0px)"
            : "translateY(-90px)",
          opacity: loadMenu ? (changeMenu ? "0" : "1") : "0",
        }}
        role="button"
        onClick={() => {
          activateMessageSec();
        }}
      >
        <div
          style={{
            position: "relative",
            height: "100%",
            width: "100%",
            top: "0px",
            left: "0px",
          }}
        >
          <img
            src={messageNavIcon}
            className="messIconNav"
            alt="Message Icon"
          />
          <img
            src={messageRollNavIcon}
            className="messIconNavRoll"
            alt="Message Roll Icon"
          />
        </div>
      </div>
      <div
        className={
          loadMenu
            ? changeMenu
              ? "respMenuHolderClose"
              : "respMenuHolder"
            : "respMenuHolderHide"
        }
      >
        <div className="relWrapper">
          <div
            className="respMenu"
            role="button"
            onClick={() => {
              setSideMenuActive(!sideMenuActive);
            }}
          >
            <div className="butRelInner">
              <div className="creamBox"> </div>{" "}
              <div className={sideMenuActive ? "barHolderHide" : "barHolder"}>
                <div className="barOneTwo"> </div>{" "}
                <div className="barOneTwo"> </div>{" "}
                <div className="barOneTwo"> </div>{" "}
              </div>{" "}
              <div className={sideMenuActive ? "xBarHolder" : "xBarHolderHide"}>
                <div className="butRelInner">
                  <div className="xBarResMenOne"> </div>{" "}
                  <div className="xBarResMenTwo"> </div>{" "}
                </div>{" "}
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </div>{" "}
      <div className={changeMenu ? "logoMenuBtnHold" : "logoMenuBtnHoldHide"}>
        <div className="relWrapper">
          <img
            src={GreyTriangle}
            className="greyMenuTriangle"
            alt="Grey Triangle"
          />
          <img
            src={VMBSLogoMenu}
            className="menuLogoScrolled"
            alt="VMBS Logo"
            onClick={() => {
              navBarclick(HomeSection);
            }}
          />{" "}
          <div
            className="scrollLeftMenu"
            role="button"
            onClick={() => {
              setSideMenuActive(!sideMenuActive);
            }}
          >
            <div className="butRelInner">
              <div className="navyBox"> </div>
              <div className="barHolderSM">
                <div className="barOneTwoSmalMen "> </div>{" "}
                <div className="barOneTwoSmalMen "> </div>{" "}
                <div className="barOneTwoSmalMen "> </div>{" "}
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </div>{" "}
      <div
        ref={sideMenuRef}
        className={sideMenuActive ? "hideShowMenu" : "hideSideMenu"}
        role="navigation"
      >
        <div className="relSideMenu">
          <div>
            <img
              src={VMBSSideBotLogo}
              className="sideMenuLogo"
              alt="VMBS Logo"
              onClick={() => {
                navBarclick(HomeSection);
                setSideMenuActive(!sideMenuActive);
              }}
            />{" "}
          </div>{" "}
          <div className="holdNavList">
            <ul className="navItems">
              <li className="navItem">
                <button
                  className="linkButton"
                  onClick={() => {
                    navBarclick(AboutSection);
                    setSideMenuActive(!sideMenuActive);
                  }}
                >
                  About{" "}
                </button>{" "}
              </li>{" "}
              <li className="navItem">
                <button
                  className="linkButton"
                  onClick={() => {
                    navBarclick(ServicesSection);
                    setSideMenuActive(!sideMenuActive);
                  }}
                >
                  Services{" "}
                </button>{" "}
              </li>{" "}
              <li className="navItem">
                <button
                  className="linkButton"
                  onClick={() => {
                    navBarclick(ContactSection);
                    setSideMenuActive(!sideMenuActive);
                  }}
                >
                  Contact{" "}
                </button>{" "}
              </li>{" "}
              <li className="navItem">
                <button
                  className="linkButton"
                  onClick={() => {
                    navBarclick(ContactSection);
                    setSideMenuActive(!sideMenuActive);
                  }}
                >
                  Location{" "}
                </button>{" "}
              </li>{" "}
            </ul>{" "}
          </div>
          <div
            className="closeMenu"
            role="button"
            onClick={() => {
              setSideMenuActive(!sideMenuActive);
            }}
          >
            <div className="butRelInner">
              <div className="lightGreyBox"> </div>{" "}
              <div className="xBarOne"> </div> <div className="xBarTwo"> </div>
            </div>{" "}
          </div>
        </div>{" "}
      </div>{" "}
    </>
  );
};

export default Navbar;
