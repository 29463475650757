import React, { useState, useEffect, useRef } from "react";
import LocationNavyTriangle from "../images/LocationNavyTriangle.svg";
import VMBSSideBotLogo from "../images/VMBSSideBotLogo.svg";
import useOuterClick from "./useOuterClick";

const ContactLocationSection = ({ navBarclick }) => {
  const [gMapShowing, setGMapShowing] = useState(false);
  const HomeSection = "HomeSection";
  const googMapRef = useOuterClick(e => {
    closeGoogMap();
  });
  const closeGoogMap = () => {
    let mapAt = gMapShowing;
    if (mapAt) {
      setGMapShowing(false);
    }
  };

  return (
    <div id="ContactLocation" className="contactLocationSec">
      <div id="ContactSection" className="contactSectionOuter">
        <div className="contactWrapper">
          <div className="contactText"> Contact </div>
          <div
            style={{
              marginBottom: "6px",
            }}
          >
            <span className="viconSec-envelop"> </span>
            <a
              className="emailConText"
              id="VMBS-Email"
              href="mailto: cmiller@millerbrotherstaffing.com"
            >
              <strong>
                <span>
                  <b style={{ fontSize: "14px", marginRight: "2px" }}>
                    cmiller
                  </b>
                  @
                </span>
                <span style={{ fontSize: "12px", marginLeft: "2px" }}>
                  <b>millerbrotherstaffing</b>
                </span>
                <span style={{ fontSize: "12px", marginLeft: "2px" }}>
                  .com
                </span>
              </strong>
            </a>{" "}
          </div>
          <div
            style={{
              marginBottom: "4px",
            }}
          >
            <span className="vicon-Contact-Phone"> </span>
            <a
              className="phoneConText"
              id="VMBS-Phone-One"
              href="tel:18142034814"
            >
              {" "}
              <strong>
                <span> 1.412 </span>
                <span>.965</span> <span> .6944 </span>{" "}
              </strong>
            </a>{" "}
            {/* <span className="orTxt"> or </span> */}
          </div>
          <div style={{ marginBottom: "11px" }}>
            {/* <a
              id="VMBS-Phone-Two"
              className="phoneConText"
              style={{ marginLeft: "40px" }}
              href="tel:18142034817"
            >
              <strong>
                {" "}
                <span> 1.814 </span>
                <span>.203</span> <span> .4817 </span>
              </strong>{" "}
            </a> */}
          </div>
          <div
            style={{
              marginBottom: "20px",
            }}
          >
            <span className="faxContactTxt"> FAX </span>
            <a className="phoneConText" id="VMBS-Fax" href="fax:18148070490">
              {" "}
              <span> 1.814 </span>
              <span>.807</span> <span> .4490 </span>{" "}
            </a>{" "}
          </div>
          <div className="socialIcons">
            <div
              className="socialIconBtn"
              role="link"
              onClick={event => {
                event.preventDefault();
                window.open(
                  "https://www.facebook.com/profile.php?id=100088537766698"
                );
              }}
            >
              {" "}
              <span className="vicon-facebook"> </span>{" "}
            </div>{" "}
            {/* <div
              className="socialIconBtn"
              role="link"
              // onClick={event => {
              //   event.preventDefault();
              //   window.open("https://www.linkedin.com/company/v-mbs", "_blank");
              // }}
            >
              {" "}
              <span className="vicon-linkedin2"> </span>{" "}
            </div> */}
            {/* <div
              className="socialIconBtn"
              role="link"
              // onClick={event => {
              //   event.preventDefault();
              //   window.open("https://twitter.com/VantageMBS", "_blank");
              // }}
            >
              {" "}
              <span className="vicon-twitter"> </span>{" "}
            </div>{" "} */}
          </div>{" "}
        </div>
      </div>
      <div id="LocationSection" className="locationSectionOuter">
        <div className="locationWrapper">
          <div className="locationText"> Location </div>{" "}
          <div
            style={{
              display: "inline-block",
              marginBottom: "70px",
            }}
          >
            <span className="vicon-Location-Address"> </span>{" "}
            <div
              className="addressHolder"
              role="link"
              onClick={event => {
                event.preventDefault();
                window.open("https://goo.gl/maps/dEFaDyrcog4WKjEy9", "_blank");
              }}
            >
              <span className="locAddress"> 360 Chestnut Street </span>
              <span className="locAddress">Meadville, PA 16335</span>{" "}
            </div>{" "}
          </div>
          <div>
            <div className="mapViewIcon">
              {" "}
              <div
                className="viewMapBtn"
                role="button"
                onClick={() => {
                  setGMapShowing(!gMapShowing);
                }}
              >
                {" "}
                <span className="vicon-View-Icon"> </span>
                <span className="viewMapText">View Map</span>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="navyBotDis"> </div>{" "}
      <img
        src={LocationNavyTriangle}
        alt="LocationTriangle"
        className="locationTriangleImg"
      />
      <img
        src={VMBSSideBotLogo}
        className="botLocationLogo"
        alt="VMBS Bottom Logo"
        onClick={() => {
          navBarclick(HomeSection);
        }}
      />
      <div
        ref={googMapRef}
        className={gMapShowing ? "showGMapDiv" : "hideGMapDiv"}
      >
        <div className="mapWrapper">
          <div
            className="closeMenu"
            role="button"
            onClick={() => {
              setGMapShowing(!gMapShowing);
            }}
          >
            <div className="butRelInner">
              <div className="lightGreyBox"> </div>{" "}
              <div className="xBarOne"> </div> <div className="xBarTwo"> </div>
            </div>{" "}
          </div>
          <div className="mapHolder">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2981.861683472903!2d-80.15146324945613!3d41.63712107914023!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x883257736f213913%3A0xfda4a218797c0997!2s360%20Chestnut%20St%2C%20Meadville%2C%20PA%2016335!5e0!3m2!1sen!2sus!4v1585126596903!5m2!1sen!2sus"
              width="250"
              height="250"
              style={{
                border: 0,
              }}
              allowFullScreen={true}
              tabIndex="0"
            >
              {" "}
            </iframe>
          </div>
        </div>
      </div>
      <div className="copyRightSecOuter">
        <div className="copyRightText">
          <span className="vicon-Copyright-Icon"> </span>{" "}
          <span className="twoTwentyThree"> 2024 </span>{" "}
          {/* <b className="vantageCopy"> Construction </b>{" "} */}
          <span className="andText"> </span>{" "}
          <b className="MBSCopy"> MBS Construction </b>{" "}
        </div>{" "}
      </div>{" "}
    </div>
  );
};

export default ContactLocationSection;
