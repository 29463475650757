import React, { useEffect, useState, useRef } from "react";
import ServicesLeftSide from "../images/ServicesLeftSide.png";
import ServicesRightSide from "../images/ServicesRightSide.png";
import ServiceRightSideRespons from "../images/ServiceRightSideRespons.png";
import expectImage from "../images/expectImage.svg";
import useOuterClick from "./useOuterClick";
import FormInput from "./FormInput/FormInput";
import EmailInput from "./EmailInput/EmailInput";
import PhoneInput from "./PhoneInput/PhoneInput";
import ParagraphText from "./ParagraphText/ParagraphText";
import { fetchPostService } from "../CommonServices/fetchService";

const ServicesSection = ({ serviceSecClick }) => {
  const [serveInfoSec, setServeInfoSec] = useState(false);
  const [registerSec, setRegisterSec] = useState(false);
  const [inquirySec, setInquirySec] = useState(false);
  const [staffServices, setStaffServices] = useState(false);
  const [positionsSec, setPostionsSec] = useState(false);
  const [employerExpect, setEmployerExpect] = useState(false);
  const [candidateExpect, setCandidateExpect] = useState(false);
  const [resumeUpClass, setResumeUpClass] = useState("fileInputCover");
  const [resumeUpload, setResumeUpload] = useState(false);
  const [thankCandSec, setThankCandSec] = useState(false);
  const [thankInqSec, setThankInqSec] = useState(false);
  const [inquiryFirst, setInquiryfirst] = useState("");
  const [inquiryLast, setInquiryLast] = useState("");
  const [inquiryEmail, setInquiryEmail] = useState("");
  const [inquiryPhone, setInquiryPhone] = useState("");
  const [resumeFile, setResumeFile] = useState("");
  const [inquiryAddNotes, setInquiryAddNotes] = useState("");
  const [resumeText, setResumeText] = useState("Upload Resume");
  const [empCandExpHeight, setEmpCandExpHeight] = useState("0px");
  const serviceSecRef = useRef();
  const resumeInput = useRef(null);
  const empExpList = useRef();
  const candExpList = useRef();

  const contentAreaRef = useOuterClick(e => {
    checkContectAreaStat();
  });
  const checkContectAreaStat = () => {
    let contentStat = serveInfoSec;
    if (contentStat) {
      setServeInfoSec(false);
      setStaffServices(false);
      setThankInqSec(false);
      setPostionsSec(false);
      setThankCandSec(false);
      setEmployerExpect(false);
      setCandidateExpect(false);
    }
  };
  const resetContactForm = () => {
    setInquiryfirst("");
    setInquiryLast("");
    setInquiryEmail("");
    setInquiryPhone("");
    setInquiryAddNotes("");
  };
  const passFirstName = value => {
    let firstName = value;
    setInquiryfirst(firstName);
  };

  const passLastName = value => {
    let lastName = value;
    setInquiryLast(lastName);
  };

  const resumeUploadFunc = event => {
    let resume = event.target.files[0];
    let fileName = resumeInput.current.files[0].name;
    let fileHas = resume ? true : false;
    setResumeFile(resume);
    setResumeText(fileName);
    setResumeUpload(fileHas);
    setResumeUpClass("fileInputChosen");
  };

  const cleareResumeFile = () => {
    setResumeFile("");
    setResumeText("Upload Resume");
    setResumeUpload(false);
    setResumeUpClass("fileInputCover");
  };

  const additionalNotes = value => {
    let notes = value;
    setInquiryAddNotes(notes);
  };

  const registerCandidate = () => {
    const reader = new FileReader();
    reader.readAsDataURL(resumeFile);
    reader.onload = () => {
      fetchPostService("/api/v1/cmbs/submitResume", {
        fileBuffer: reader.result,
        fileName: resumeFile.name,
        firstName: inquiryFirst,
        lastName: inquiryLast,
        email: inquiryEmail,
        phone: inquiryPhone,
        note: inquiryAddNotes,
      })
        .then(res => {
          setThankCandSec(true);
          setTimeout(() => {
            setServeInfoSec(false);
            resetRegistration();
            cleareResumeFile();
          }, 7000);
          console.log(res);
        })
        .catch(err => {
          alert("Failed, please try submitting again");
          console.log(err);
        });
    };
    reader.onerror = err => {
      console.log(err);
    };
  };

  const resetRegistration = () => {
    setTimeout(() => {
      setRegisterSec(false);
      setThankCandSec(false);
      resetContactForm();
    }, 320);
  };

  const submitInquiry = () => {
    document.getElementById("main-loader").style.display = "block";
    document.getElementById("main-loader").style.opacity = "0.7";
    fetchPostService("/api/v1/cmbs/submitInquiry", {
      firstName: inquiryFirst,
      lastName: inquiryLast,
      email: inquiryEmail,
      phone: inquiryPhone,
      note: inquiryAddNotes,
    })
      .then(response => {
        document.getElementById("main-loader").style.display = "none";
        setThankInqSec(true);
        resetContactForm();
        setTimeout(() => {
          setServeInfoSec(false);
          resetInquiry();
        }, 7000);
      })
      .catch(err => {
        document.getElementById("main-loader").style.display = "none";
        console.log(err);
        alert("Failed, please try submitting again");
      });
  };

  const resetInquiry = () => {
    setInquirySec(false);
    setThankInqSec(false);
  };

  const inquirySecClose = () => {
    setInquirySec(false);
    resetContactForm();
  };

  const registerSecClose = () => {
    setRegisterSec(false);
    resetContactForm();
  };

  const inquirySecFunc = () => {
    setServeInfoSec(true);
    setInquirySec(true);
    setPostionsSec(false);
    setStaffServices(false);
    setRegisterSec(false);
    setThankInqSec(false);
    setThankCandSec(false);
    setEmployerExpect(false);
    setCandidateExpect(false);
  };
  const registrationSecFunc = () => {
    setServeInfoSec(true);
    setRegisterSec(true);
    setStaffServices(false);
    setInquirySec(false);
    setPostionsSec(false);
    setThankInqSec(false);
    setThankCandSec(false);
    setEmployerExpect(false);
    setCandidateExpect(false);
  };
  const staffServeFunc = () => {
    setServeInfoSec(true);
    setStaffServices(true);
    setInquirySec(false);
    setRegisterSec(false);
    setPostionsSec(false);
    setThankInqSec(false);
    setThankCandSec(false);
    setEmployerExpect(false);
    setCandidateExpect(false);
  };

  const positionSectFunc = () => {
    setServeInfoSec(true);
    setPostionsSec(true);
    setStaffServices(false);
    setInquirySec(false);
    setRegisterSec(false);
    setThankInqSec(false);
    setThankCandSec(false);
  };
  const staffServeClose = () => {
    setStaffServices(false);
  };
  const positionSectClose = () => {
    setPostionsSec(false);
    setEmployerExpect(false);
    setCandidateExpect(false);
  };
  const employersExpectSec = () => {
    let empHgt = empExpList.current.clientHeight + 5;
    let empHgtPass = "" + empHgt + "px";
    setEmpCandExpHeight(empHgtPass);
    setEmployerExpect(true);
  };

  const employersCloseExpectSec = () => {
    setEmpCandExpHeight("0px");
    setTimeout(() => {
      setEmployerExpect(false);
    }, 160);
  };

  const empCloseCandOpenSec = () => {
    setEmpCandExpHeight("0px");
    setTimeout(() => {
      setEmployerExpect(false);
      setTimeout(() => {
        candidateExpectSec();
      }, 160);
    }, 160);
  };

  const candidateExpectSec = () => {
    let candHgt = candExpList.current.clientHeight + 5;
    let candHgtPass = "" + candHgt + "px";
    setEmpCandExpHeight(candHgtPass);
    setCandidateExpect(true);
  };

  const candidateCloseExpectSec = () => {
    setEmpCandExpHeight("0px");
    setTimeout(() => {
      setCandidateExpect(false);
    }, 160);
  };

  const candCloseEmpOpenSec = () => {
    setEmpCandExpHeight("0px");
    setTimeout(() => {
      setCandidateExpect(false);
      setTimeout(() => {
        employersExpectSec();
      }, 160);
    }, 160);
  };

  return (
    <div
      id="ServicesSection"
      className="servicesSectionHolder"
      style={{ zIndex: serveInfoSec ? "950" : "650" }}
    >
      <img
        src={ServicesLeftSide}
        alt="ServiceBackLeft"
        className="serviceBackLftImg"
      />
      <img
        src={ServicesRightSide}
        alt="ServiceBackRight"
        className="serviceBackRghtImg"
      />
      <img
        src={ServiceRightSideRespons}
        alt="ServiceBackRightRespons"
        className="serviceBackRightRespns"
      />
      <div className="ourServeTxt">
        <span> Our </span> <b> Services </b>{" "}
      </div>{" "}
      <div
        id="ServiceBtnHold"
        className="mainButtonHolder"
        style={{ opacity: serveInfoSec ? "0" : "1" }}
      >
        <div
          id="StaffServicesBtn"
          className="servicesButton"
          role="button"
          onClick={() => {
            staffServeFunc();
            serviceSecClick();
          }}
        >
          <span className="serviceBtnTxt">
            <b> We Specialize </b> In{" "}
          </span>{" "}
          <span className="serviceBtnTxtSmall">Customer Service Options </span>{" "}
        </div>{" "}
        <div
          id="PositionsFill"
          className="servicesButton"
          role="button"
          onClick={() => {
            positionSectFunc();
            serviceSecClick();
          }}
        >
          <span className="serviceBtnTxt">
            <b> Materials </b> On Hand{" "}
          </span>{" "}
          <span className="serviceBtnTxtSmall"> Ready to Go Materials </span>{" "}
        </div>{" "}
        <div
          id="InquiryComp"
          className="inquiryButtonBlue"
          role="button"
          onClick={() => {
            inquirySecFunc();
            serviceSecClick();
          }}
        >
          <span className="viconInq-inquiry-icon"> </span>{" "}
          <span className="inquiryBtnTxt"> Inquiry </span>{" "}
          <span className="inquiryBtnTxtSmall"> Company </span>{" "}
        </div>{" "}
        {/* <div
          id="RegistCand"
          className="inquiryButtonRed"
          role="button"
          onClick={() => {
            // registrationSecFunc();
            // serviceSecClick();
          }}
        >
          <div
            style={{
              position: "relative",
              width: "100%",
              height: "100%",
            }}
          >
            <span className="vicon-Register-Icon"> </span>{" "}
            <span className="registerBtnTxt"> Request </span>{" "}
            <span className="registerBtnTxtSmall"> Quote </span>{" "}
          </div>
        </div> */}
      </div>
      <div
        ref={contentAreaRef}
        className={serveInfoSec ? "serveInfoShow" : "serveInfoHide"}
      >
        <div className="serveInfoRelInnder">
          {staffServices ? (
            <h4 className="staffServeHeader">
              <b>Our Staffing</b> Services
            </h4>
          ) : null}
          {positionsSec ? (
            <h4 className="staffServeHeader">
              <b>Materials</b> On Hand
            </h4>
          ) : null}
          {registerSec && !thankInqSec ? (
            <h4 className="candidateRegHeader">Candidate Registration</h4>
          ) : null}
          {inquirySec && !thankInqSec ? (
            <h4 className="candidateRegHeader">Company/Client Inquiry</h4>
          ) : null}
          <div
            className={
              registerSec && thankCandSec
                ? "thankYouDivShow"
                : "thankYouDivHide"
            }
          >
            <h4 className="thankYouHeader">
              Thank You for Registering with Us!
            </h4>
            <span className="beInTouch">
              A representatives with be in touch in the next 48 hours.
            </span>
          </div>
          <div
            className={
              inquirySec && thankInqSec ? "thankYouDivShow" : "thankYouDivHide"
            }
          >
            <h4 className="thankYouHeader">
              We Appreciate Your Interest in Our Services!
            </h4>
            <span className="beInTouch">
              One of our representatives with be in touch in the next 48 hours.
            </span>
          </div>
          <div
            id="serviceScrollArea"
            className="serviceInfoScrollBar"
            style={{
              paddingTop: "0px",
              maxHeight: "100%",
              overflowY: "scroll",
              overflowX: "hidden",
              pointerEvents: "all",
              touchAction: "manipulation",
            }}
          >
            {registerSec ? (
              <div
                style={{
                  opacity: registerSec && !thankCandSec ? "1" : "0",
                  paddingTop: registerSec && !thankCandSec ? "0px" : "30px",
                }}
              >
                <div
                  className="formHolderSection"
                  style={{ opacity: registerSec && !thankCandSec ? "1" : "0" }}
                >
                  <div className="inputHolderInline">
                    <FormInput
                      inputTitle={"Your First Name"}
                      inputAction={"First Name"}
                      needed={"* Need Initial"}
                      passFormInputVal={value => {
                        passFirstName(value);
                      }}
                    />
                  </div>
                  <div className="inputHolderInline">
                    <FormInput
                      inputTitle={"Your Last Name"}
                      inputAction={"Last Name"}
                      needed={"* Required"}
                      passFormInputVal={value => {
                        passLastName(value);
                      }}
                    />
                  </div>
                  <div className="inputHolderInline">
                    <EmailInput
                      inputTitle={"Your Preferred Email"}
                      inputAction={"Valid Email"}
                      passFormInputVal={value => {
                        setInquiryEmail(value);
                      }}
                    />
                  </div>
                  <div className="inputHolderInline">
                    <PhoneInput
                      inputTitle={"Daytime Phone Number"}
                      inputAction={"Valid Number"}
                      passFormInputVal={value => {
                        setInquiryPhone(value);
                      }}
                    />
                  </div>
                  <div className="inputHolderFileIn">
                    <input
                      ref={resumeInput}
                      type="file"
                      name="uploaded_file"
                      accept=".doc,.docx,.pdf"
                      onChange={resumeUploadFunc}
                      onMouseOver={() => {
                        setResumeUpClass("fileInputChoose");
                      }}
                      onMouseOut={() => {
                        setResumeUpClass("fileInputCover");
                      }}
                    />
                    <div className={resumeUpClass}>
                      <span
                        className={
                          resumeUpload
                            ? "vicon-attachment"
                            : "vicon-folder-upload"
                        }
                        style={{
                          color: "#8da7dc",
                          marginRight: "10px",
                          position: "relative",
                          fontSize: "18px",
                          top: "2px",
                        }}
                      />
                      <span
                        style={{ color: resumeUpload ? "#ffffff" : "#c7daff" }}
                      >
                        {resumeText}
                      </span>
                    </div>
                    {resumeUpload ? (
                      <div
                        className="cancelFile"
                        role="button"
                        onClick={() => {
                          cleareResumeFile();
                        }}
                      >
                        <span className="viconInq-x-icon" />
                      </div>
                    ) : null}
                  </div>
                  <div className="textBoxHolderIn">
                    <ParagraphText
                      textStart={"Any Additional Notes to Pass On?"}
                      textAction={"Additional Notes"}
                      passValue={value => {
                        additionalNotes(value);
                      }}
                    />
                  </div>
                  <div className="submitHolderIn">
                    <div
                      // className="submitInqButton"
                      className={
                        "submitInqButton " +
                        (!inquiryFirst ||
                        !inquiryLast ||
                        !inquiryPhone ||
                        !inquiryEmail ||
                        !resumeFile
                          ? " submitInqButton-disabled"
                          : "")
                      }
                      role="button"
                      onClick={() => {
                        inquiryFirst &&
                          inquiryLast &&
                          inquiryPhone &&
                          inquiryEmail &&
                          resumeFile &&
                          registerCandidate();
                      }}
                    >
                      <span
                        className="vicon-Register-Icon"
                        style={{
                          color: "#a5c3ff",
                          marginRight: "10px",
                          marginLeft: "0px",
                          position: "relative",
                          fontSize: "18px",
                          top: "2px",
                        }}
                      />
                      <span>Complete Registration</span>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            {inquirySec ? (
              <div
                className="formHolderSection"
                style={{ opacity: inquirySec && !thankInqSec ? "1" : "0" }}
              >
                <div className="inputHolderInline">
                  <FormInput
                    inputTitle={"Contact First Name"}
                    inputAction={"First Name"}
                    needed={"* Need Initial"}
                    passFormInputVal={value => {
                      passFirstName(value);
                    }}
                  />
                </div>
                <div className="inputHolderInline">
                  <FormInput
                    inputTitle={"Contact Last Name"}
                    inputAction={"Last Name"}
                    needed={"* Required"}
                    passFormInputVal={value => {
                      passLastName(value);
                    }}
                  />
                </div>
                <div className="inputHolderInline">
                  <EmailInput
                    inputTitle={"Contact Preferred Email"}
                    inputAction={"Valid Email"}
                    passFormInputVal={value => {
                      setInquiryEmail(value);
                    }}
                  />
                </div>
                <div className="inputHolderInline">
                  <PhoneInput
                    inputTitle={"Contact Phone Number"}
                    inputAction={"Valid Number"}
                    passFormInputVal={value => {
                      setInquiryPhone(value);
                    }}
                  />
                </div>
                <div className="textBoxHolderIn">
                  <ParagraphText
                    textStart={"Any Additional Information to Pass On?"}
                    textAction={"Additional Information"}
                    passValue={value => {
                      additionalNotes(value);
                    }}
                  />
                </div>
                <div className="submitHolderIn">
                  <div
                    className={
                      "submitInqButton " +
                      (!inquiryFirst ||
                      !inquiryLast ||
                      !inquiryPhone ||
                      !inquiryEmail
                        ? " submitInqButton-disabled"
                        : "")
                    }
                    role="button"
                    onClick={() => {
                      inquiryFirst &&
                        inquiryLast &&
                        inquiryPhone &&
                        inquiryEmail &&
                        submitInquiry();
                    }}
                  >
                    <span
                      className="viconInq-inquiry-icon"
                      style={{
                        color: "#a5c3ff",
                        marginRight: "11px",
                        marginLeft: "0px",
                        position: "relative",
                        fontSize: "20px",
                        top: "4px",
                      }}
                    />
                    <span>Submit Inquiry</span>
                  </div>
                </div>
              </div>
            ) : null}

            {staffServices ? (
              <div className="staffServiceSection">
                <h4>Listed Services</h4>
                <ul>
                  <li className="serveListLI">
                    <b>Kitchen remodelling</b> <b>Bathroom</b>{" "}
                    <span>Remodelling</span>
                  </li>
                  <li className="serveListLI">
                    <b>Concrete Exterior</b>
                  </li>
                  <li className="serveListLI">
                    <b>Siding Exterior</b>
                  </li>
                  <li className="serveListLI">
                    <b>Custom Tile Work</b>{" "}
                  </li>
                  <li className="serveListLI">
                    <b>Lamanent Floors</b> and{" "}
                    <b>Other Services Available Upon Request</b>
                  </li>
                </ul>
              </div>
            ) : null}
            {positionsSec ? (
              <div className="staffServiceSection">
                {/* <div style={{ marginBottom: "20px" }}>
                  <img
                    src={expectImage}
                    alt="What to Expect Image"
                    className="whatExpectImg"
                  />
                  <h5 className="whatExpectHead">Materials On Hand</h5>
                </div> */}
                <div>
                  <div
                    className={employerExpect ? "empCandOppBtn" : "empCandBtn"}
                    role="button"
                    onClick={() => {
                      !employerExpect &&
                        !candidateExpect &&
                        employersExpectSec();
                      employerExpect &&
                        !candidateExpect &&
                        employersCloseExpectSec();
                      !employerExpect &&
                        candidateExpect &&
                        candCloseEmpOpenSec();
                    }}
                  >
                    {employerExpect ? (
                      <span
                        className="viconInq-x-icon"
                        style={{
                          position: "relative",
                          marginRight: "10px",
                          top: "2px",
                          fontSize: "13px",
                        }}
                      />
                    ) : (
                      <span
                        className="viconSec-aid-kit"
                        style={{
                          position: "relative",
                          marginRight: "10px",
                          top: "1px",
                          fontSize: "15px",
                        }}
                      />
                    )}
                    Exterior
                  </div>
                  <div
                    className={candidateExpect ? "empCandOppBtn" : "empCandBtn"}
                    role="button"
                    onClick={() => {
                      !candidateExpect &&
                        !employerExpect &&
                        candidateExpectSec();
                      candidateExpect &&
                        !employerExpect &&
                        candidateCloseExpectSec();
                      !candidateExpect &&
                        employerExpect &&
                        empCloseCandOpenSec();
                    }}
                  >
                    {candidateExpect ? (
                      <span
                        className="viconInq-x-icon"
                        style={{
                          position: "relative",
                          marginRight: "10px",
                          top: "2px",
                          fontSize: "13px",
                        }}
                      />
                    ) : (
                      <span
                        className="vicon-user"
                        style={{
                          position: "relative",
                          marginRight: "10px",
                          top: "1px",
                          fontSize: "15px",
                        }}
                      />
                    )}
                    Interior
                  </div>
                </div>
                <div
                  className="expectSecShowHide"
                  style={{ height: empCandExpHeight }}
                >
                  <ul
                    ref={empExpList}
                    className="expectShowListUL"
                    style={{ opacity: employerExpect ? "1" : "0" }}
                  >
                    <li className="serveListLI">
                      <b>Vinyl or Composite Siding</b>
                    </li>
                    <li className="serveListLI">
                      <b>Exterior Concrete</b>
                    </li>
                    <li className="serveListLI">
                      <b>Custom Wood or Composite Deck</b>
                    </li>
                    <li className="serveListLI">
                      <b>Metal, Shingles or Pannel Roofing</b>
                    </li>
                  </ul>
                  <ul
                    ref={candExpList}
                    className="expectShowListUL"
                    style={{ opacity: candidateExpect ? "1" : "0" }}
                  >
                    <li className="serveListLI">
                      <b> Custom Bathroom Tiles</b>
                    </li>
                    <li className="serveListLI">
                      <b>Custom Shower Tiles</b>
                    </li>
                    <li className="serveListLI">
                      <b>Laminate Floors</b>
                    </li>
                    <li className="serveListLI">
                      <b>Custom Kitchen Tiles and Counter Tops</b>
                    </li>
                    <li className="serveListLI">
                      <b>Concrete Floor for Basement and Utilities rooms</b>
                    </li>
                  </ul>
                </div>
                {/* <h4>Positions</h4>
                <ul>
                  <li className="serveListLI">
                    <b>Registered Nurse</b>
                    <span className="explainTermText">
                      - <b>Psych, </b>
                      <b>Detox, </b>
                      <b>Med/Surgery, </b>
                      <b>Telemetry, </b>
                      <b>Dialysis, </b>
                      <b>ICU, </b>
                      <b>ED, </b>
                      <b>PACU, </b>
                      <b>and Operating Room</b>
                    </span>
                  </li>
                  <li className="serveListLI">
                    <b>Licensed Practical Nurse</b>
                  </li>
                  <li className="serveListLI">
                    <b>Medical Assistant</b>
                  </li>
                  <li className="serveListLI">
                    <b>Technicians </b>
                    <span className="explainTermText">
                      - <b>Monitor Tech, </b>
                      <b>Surgical Tech (General), </b>
                      <b>CVOR Surgical Tech, </b>
                      <b>Med Lab Tech/Med Tech, </b>
                      <b>Cyto Tech/Histo Tech, </b>
                      <b>X Ray Tech, </b>
                      <b>CT/MRI, </b>
                      <b>Echo Tech (Adults), </b>
                      <b>Echo Tech (Peds), </b>
                      <b>and Cath Lab Tech</b>
                    </span>
                  </li>
                  <li className="serveListLI">
                    <b>Therapist </b>
                    <span className="explainTermText">
                      - <b>Cardiac Resynchronization Therapist, </b>
                      <b>Renal Replacement Therapist, </b>
                      <b>Physical Therapist, </b>
                      <b>Occupational Therapist, </b>
                      <b>Speech Language Pathologist</b>
                    </span>
                  </li>
                </ul> */}
              </div>
            ) : null}
          </div>
          <div className="closeButtonHolder">
            <div
              className="closeInfoButton"
              role="button"
              onClick={() => {
                setServeInfoSec(false);
                registerSec && registerSecClose();
                inquirySec && inquirySecClose();
                staffServices && staffServeClose();
                positionsSec && positionSectClose();
              }}
            >
              <div className="greyBtnBack">
                <span className="viconInq-x-icon" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesSection;
