import React, { useRef } from "react";
import VMBSHomeLogo from "../images/VMBSHomeLogo.svg";
import HomeBackLeft from "../images/HomeBackLeft.svg";
import HomeBackLeftOver from "../images/HomeBackLeftOver.svg";
import VMHomeImage from "../images/VMHomeImage.png";
import VMBSSideBotLogo from "../images/VMBSSideBotLogo.svg";
import VMBSTopText from "../images/VMBSTopText.svg";
import medStafServTxt from "../images/medStafServTxt.svg";

export default function HomeSection({
  relSecCl,
  offset,
  loaded,
  loadText,
  gradDark,
  gradLight,
  scrolledDown,
}) {
  return (
    <div id="HomeSection" className="homeSecWrapper">
      <div
        className="homeNavyOverlay"
        style={{
          opacity: gradDark,
        }}
      />
      <div className={relSecCl}>
        <div className="relWrapper">
          <div className="homeTopLeft">
            <div className="relWrapper">
              <img
                src={HomeBackLeft}
                alt="HomeLeftBack"
                className={loaded ? "homeTopLeftImg" : "homeTopLeftImgHid"}
              />{" "}
              <img
                src={HomeBackLeftOver}
                alt="HomeLeftBackOver"
                className="homeTopLeftImg"
                style={{ opacity: scrolledDown ? "1" : "0" }}
              />{" "}
            </div>{" "}
          </div>{" "}
          <div className="homeBottomRight">
            <div
              className="relWrapper"
              style={{
                opacity: gradLight,
              }}
            >
              <img
                src={VMHomeImage}
                alt="HomeRightBack"
                className={loaded ? "homeBottomRightImg" : "homeTopRightImgHid"}
              />{" "}
            </div>{" "}
          </div>{" "}
          <div className="homeTopLeft">
            {" "}
            <div className="relWrapper">
              <img
                src={VMBSHomeLogo}
                alt="HomeLogo"
                className={loaded ? "homeLogoImg" : "homeLogoImgHid"}
              />{" "}
              <img
                src={VMBSSideBotLogo}
                alt="HomeLogoRed"
                className="homeLogoImg"
                style={{ opacity: scrolledDown ? "1" : "0" }}
              />{" "}
            </div>{" "}
          </div>{" "}
          <div className={loadText ? "topTextHolder" : "topTextHolderHide"}>
            <div className="relWrapper">
              <img
                src={VMBSTopText}
                alt="Vantage and MBS"
                className="topTextImg"
              />
            </div>{" "}
          </div>{" "}
          <div
            className={loadText ? "topBotTextHolder" : "topBotTextHolderHide"}
          >
            <div className="relWrapper">
              <img
                src={medStafServTxt}
                alt="Medical Staffing Services"
                className="topBotTextImg"
              />
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </div>{" "}
      <div
        className={scrolledDown ? "homeBlueishOverlay " : "homeBlueishHide"}
      />{" "}
    </div>
  );
}
