import React, { useState, useRef, useEffect } from "react";
import "./ParagraphText.css";
import TextareaAutosize from "react-textarea-autosize";

const inputNotReady = "textBoxNotReady";
const inputStart = "textBoxStart";
const inputSetShow = "textBoxSet";
const ParagraphText = ({ textStart, textAction, passValue }) => {
  const textAreaRef = useRef(null);
  const [value, setValue] = useState("");
  const [inputIsAt, setInputReady] = useState(inputNotReady);
  const [boxTouched, setBoxTouched] = useState("textNoTouch");
  const [labelClass, setLabelClass] = useState("textLabelStart");
  const [reqValidText, setReqValidText] = useState("* Optional");
  const [labelText, setLabelText] = useState(textStart);
  const [textAreaHeight, setTextAreaHeight] = useState("auto");
  const [parentHeight, setParentHeight] = useState("auto");
  useEffect(() => {
    let height = textAreaRef.current.scrollHeight;
    let setHeight = height < 75 ? "75px" : height + "px";
    setParentHeight(setHeight);
    setTextAreaHeight(setHeight);
  }, [value]);
  const focusInput = () => {
    setBoxTouched("textTouched");
    setLabelClass("textLabelEnd");
    setInputReady(inputStart);
    setLabelText(textAction);
  };
  const onChange = event => {
    setValue(event.target.value);
    passValue(event.target.value);
    setTextAreaHeight("auto");
  };
  const onBlur = event => {
    let val = event.target.value;
    let valLength = val.length;
    if (valLength > 0) {
      setBoxTouched("textTouched");
      setLabelClass("textLabelEnd");
      setInputReady(inputSetShow);
      setLabelText(textAction);
    } else if (valLength === 0) {
      setBoxTouched("textNoTouch");
      setLabelClass("textLabelStart");
      setInputReady(inputNotReady);
      setLabelText(textStart);
    }
  };

  return (
    <div
      className="grayPara"
      style={{
        minHeight: parentHeight,
      }}
    >
      <TextareaAutosize
        ref={textAreaRef}
        // id={key}
        value={value}
        className={inputIsAt}
        onFocus={() => focusInput()}
        onChange={onChange}
        onBlur={onBlur}
        style={{
          height: textAreaHeight,
        }}
      />
      <div className="textboxParHolder">
        <div className="interReferenceDiv">
          <div className={boxTouched}> </div>
          <label className={labelClass}>{labelText}</label>
          <div className="optionTextHolder" style={{ color: "#ccdeff" }}>
            {reqValidText}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParagraphText;

//      <button type="button" onClick={() => deletePara(index)}>
//        Close
//      </button>
