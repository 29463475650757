import React, { useState } from "react";
import "./PhoneInput.css";

const inputNotReadyFI = "emailInputNotReady";
const inputStartFI = "emailInputStart";
const inputSetShowFI = "emailInputSet";

const PhoneInput = ({ inputTitle, inputAction, passFormInputVal }) => {
  const [labelFocusedFI, setLabelFocusFI] = useState(false);
  const [value, setValue] = useState("");
  const [inputIsAtFI, setInputReadyFI] = useState(inputNotReadyFI);
  const [boxTouched, setBoxTouched] = useState("phoneNoTouch");
  const [labelTextFI, setLabelTextFI] = useState(inputTitle);
  const [labelClass, setLabelClass] = useState("labelStart");
  const [reqValidText, setReqValidText] = useState("* Optional");
  const [validPhone, setValidPhone] = useState(false);

  function formatPhoneNumber(valueStr) {
    let pn = "";
    if (valueStr.length < 3) {
      return "1-(";
    }
    if (valueStr.length < 7) {
      pn = valueStr;
      if (valueStr.length == 6) {
        pn += ")-";
      }
    } else if (valueStr.length < 12 && valueStr.length > 8) {
      pn = valueStr;
      if (valueStr.length == 11) {
        pn += "-";
      }
    } else if (valueStr.length > 12 && valueStr.length < 17) {
      pn = valueStr;
    } else {
      pn = valueStr.slice(0, 16);
    }
    const temp = pn.split("").filter(num => !isNaN(num));
    if (temp[0] != "1") {
      temp.unshift("1");
    }
    const tlength = temp.length;
    if (tlength === 1) {
      temp.push("-");
    } else if (tlength <= 3 && tlength > 1) {
      temp.splice(1, 0, "-");
      temp.splice(2, 0, "(");
    } else if (tlength < 8) {
      temp.splice(1, 0, "-");
      temp.splice(2, 0, "(");
      temp.splice(6, 0, ")");
      temp.splice(7, 0, "-");
    } else {
      temp.splice(1, 0, "-");
      temp.splice(2, 0, "(");
      temp.splice(6, 0, ")");
      temp.splice(7, 0, "-");
      temp.splice(11, 0, "-");
    }
    return temp.join("");
  }

  const focusInput = () => {
    setLabelFocusFI(true);
    setBoxTouched("phoneTouched");
    setLabelClass("labelEnd");
    setInputReadyFI(inputStartFI);
    setLabelTextFI(inputAction);
  };

  const handleChange = event => {
    let phoneVal = event.target.value;
    let phoneNum = formatPhoneNumber(phoneVal);
    setValue(phoneNum);
    // passFormInputVal(phoneNum);
    if (phoneNum.length === 16) {
      setReqValidText("Thank You");
      setValidPhone(true);
      passFormInputVal(phoneNum);
    } else {
      setReqValidText("* Optional");
      setValidPhone(false);
      passFormInputVal("");
    }
  };
  const preventDef = event => {
    event.preventDefault();
  };
  const handleBlur = event => {
    const valE = validPhone;
    const val = event.target.value;
    const valLength = val.length;
    if (valLength > 0 && valE) {
      setInputReadyFI(inputSetShowFI);
      setReqValidText("Thank You");
      setLabelClass("labelEnd");
    }
    if (valLength > 0 && !valE) {
      let notVar = "Not" + " " + inputAction;
      setBoxTouched("phoneProblem");
      setLabelTextFI(notVar);
      setInputReadyFI(inputIsAtFI);
      setLabelClass("labelEnd");
    } else if (valLength === 0) {
      setBoxTouched("phoneNoTouch");
      setLabelClass("labelStart");
    }
  };
  return (
    <form onSubmit={preventDef}>
      <div className="formInputWrapper">
        <input
          type="text"
          name={inputTitle}
          value={value}
          className={inputIsAtFI}
          onFocus={focusInput}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <div className={boxTouched}> </div>
        <label className={labelClass}>{labelTextFI}</label>
        <div
          className="requiredTextHolder"
          style={{ color: validPhone ? "#ffffff" : "#ccdeff" }}
        >
          {reqValidText}
        </div>
      </div>
    </form>
  );
};

export default PhoneInput;
