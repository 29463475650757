import React, { useState } from "react";
import "./EmailInput.css";

const inputNotReadyFI = "emailInputNotReady";
const inputStartFI = "emailInputStart";
const inputSetShowFI = "emailInputSet";

const EmailInput = ({ inputTitle, inputAction, passFormInputVal }) => {
  const [labelFocusedFI, setLabelFocusFI] = useState(false);
  const [value, setValue] = useState("");
  const [inputIsAtFI, setInputReadyFI] = useState(inputNotReadyFI);
  const [boxTouched, setBoxTouched] = useState("formNoTouch");
  const [labelTextFI, setLabelTextFI] = useState(inputTitle);
  const [labelClass, setLabelClass] = useState("labelStart");
  const [reqValidText, setReqValidText] = useState("* Required");
  const [validEmail, setValidEmail] = useState(false);

  const focusInput = () => {
    setLabelFocusFI(true);
    setBoxTouched("formTouched");
    setLabelClass("labelEnd");
    setInputReadyFI(inputStartFI);
    setLabelTextFI(inputAction);
  };

  const handleChange = event => {
    let pattern = new RegExp(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
    let emailVal = event.target.value;
    setValue(emailVal);
    //passFormInputVal(event.target.value);
    if (pattern.test(emailVal)) {
      setReqValidText("Valid Email");
      setValidEmail(true);
      passFormInputVal(emailVal);
    } else {
      passFormInputVal("");
    }
  };

  const preventDef = event => {
    event.preventDefault();
  };
  const handleBlur = event => {
    const valE = validEmail;
    const val = event.target.value;
    const valLength = val.length;
    if (valLength > 0 && valE) {
      setInputReadyFI(inputIsAtFI);
      setLabelClass("labelEnd");
    }
    if (valLength > 0 && !valE) {
      let needVar = "Need" + " " + inputAction;
      setBoxTouched("formProblem");
      setLabelTextFI(needVar);
      setInputReadyFI(inputSetShowFI);
      setLabelClass("labelEnd");
    } else if (valLength === 0) {
      let needVar = "Need" + " " + inputAction;
      setLabelFocusFI(false);
      setBoxTouched("formNoTouch");
      setLabelClass("labelProblem");
    }
  };
  return (
    <form onSubmit={preventDef}>
      <div className="formInputWrapper">
        <input
          type="text"
          name={inputTitle}
          value={value}
          className={inputIsAtFI}
          onFocus={focusInput}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        <div className={boxTouched}> </div>
        <label className={labelClass}>{labelTextFI}</label>
        <div
          className="requiredTextHolder"
          style={{ color: validEmail ? "#ffffff" : "#ccdeff" }}
        >
          {reqValidText}
        </div>
      </div>
    </form>
  );
};

export default EmailInput;
